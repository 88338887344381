// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-case-studies-jsx": () => import("./../../../src/pages/case-studies.jsx" /* webpackChunkName: "component---src-pages-case-studies-jsx" */),
  "component---src-pages-general-content-jsx": () => import("./../../../src/pages/general-content.jsx" /* webpackChunkName: "component---src-pages-general-content-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-single-casestudy-jsx": () => import("./../../../src/pages/single-casestudy.jsx" /* webpackChunkName: "component---src-pages-single-casestudy-jsx" */),
  "component---src-pages-solutions-accounting-jsx": () => import("./../../../src/pages/solutions/accounting.jsx" /* webpackChunkName: "component---src-pages-solutions-accounting-jsx" */),
  "component---src-pages-solutions-custom-solutions-jsx": () => import("./../../../src/pages/solutions/custom-solutions.jsx" /* webpackChunkName: "component---src-pages-solutions-custom-solutions-jsx" */),
  "component---src-pages-solutions-ecommerce-jsx": () => import("./../../../src/pages/solutions/ecommerce.jsx" /* webpackChunkName: "component---src-pages-solutions-ecommerce-jsx" */),
  "component---src-pages-solutions-jsx": () => import("./../../../src/pages/solutions.jsx" /* webpackChunkName: "component---src-pages-solutions-jsx" */),
  "component---src-pages-solutions-online-distribution-jsx": () => import("./../../../src/pages/solutions/online-distribution.jsx" /* webpackChunkName: "component---src-pages-solutions-online-distribution-jsx" */),
  "component---src-pages-solutions-retail-jsx": () => import("./../../../src/pages/solutions/retail.jsx" /* webpackChunkName: "component---src-pages-solutions-retail-jsx" */)
}

