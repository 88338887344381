
/*
    Attach scripts that require browser runtime here in /gatsby-browser.js
    Workaround for gatsby build issue...
*/

const helperScripts = {
    mapboxgl:null,
    conversationalForm: null
}

export default helperScripts;



// ./src/utils/helper-scripts.js